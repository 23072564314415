@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&display=swap");
@import "./fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-primary;
    @apply font-black;
    @apply font-normal;
    @apply text-sm;
    @apply leading-normal;
  }
}
