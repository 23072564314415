@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplayMedium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplayLight.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplayBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplayBlack.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplayRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Wulkan Display";
  src: url("/public/fonts/subset-WulkanDisplaySemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/public/fonts/subset-AktivGrotesk-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
